<template>
  <v-layout>
    <v-icon
      v-if="userDivisions.length > 0"
      @click="drawer = true"
      large
      class="ml-3 mr-0 my-5"
      style="position:absolute"
      >mdi-menu</v-icon
    >
    <v-navigation-drawer
      v-model="drawer"
      absolute
      bottom
      temporary
      width="280"
    >

<v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ trl('FC_DIVISION_READ_menu') }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          ma
          color="primary"
          v-for="item in userDivisions"
          :key="item.id"
          link
          :to="{
            name: 'BudgetDivision',
            params: { tenant: current.path, fcid: $store.state.activeForecastId, id: item.id },
          }"
        >
          <v-list-item-icon>
            <v-icon>mdi-select-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            {{ item.name }}
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <router-view />
  </v-layout>
</template>

<script>
import { trl } from "@/utils/strings";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      drawer: false,
      subtitle: "Bitte wähle eine Unit"
    };
  },
  computed: {
    ...mapGetters(["current", "userDivisions"]),
  },
  methods: { trl
  },
  created() {

    if (this.userDivisions.length === 0) {
      this.drawer = true;
      this.subtitle = "Es existieren keine Units.";
      return;
    }

    if (this.userDivisions.length === 1 && !this.$route.params.id) {
      this.$router.replace({
        name: "BudgetDivision",
        params: { tenant: this.current.path, id: this.userDivisions[0].id },
      });
    }
    if (!this.$route.params.id) this.drawer = true;
    
  },
};
</script>

<style>
</style>